.cardItems {
  padding: 25px;
  margin: 10px;
  border: 2px solid #f5f5f7;
  width: 200px;
  font-size: 1rem;
  border-radius: 30px 0 30px 0;
  color: #f5f5f7;
}
.loadingAndError {
  color: white;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#buttonimportant {
  border: 2px solid rgba(245, 0, 87, 0.5);
  border-radius: 20px 0 20px 0;
}

.finishedApartments {
  border: 1px solid white;
  margin: 10px;
  padding: 10px;
  border-radius: 30px 0 30px 0;
}

.heading {
  color: #f5f5f7;
  margin: 0 0 20px 0;
  padding: 20px;
  text-align: center;
}

.cardMain {
  display: block;
  justify-content: center;
}

.apartmentNames {
  display: flex;
  justify-content: center;
  width: auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.finish {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #f5f5f7;
  padding: 0 5px 0 5px;
  text-align: center;
}

.finish > h3 {
  padding: 0 10px;
}

.mainStyle {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: white;
}

body {
  height: 100%;
  width: 100%;
  background: rgb(70, 76, 77);
  background: linear-gradient(
    90deg,
    rgba(70, 76, 77, 1) 0%,
    rgba(58, 66, 68, 1) 54%,
    rgba(85, 93, 94, 1) 100%
  );
}

.buttonsclass {
  font-size: large;
  width: 100px;
}
